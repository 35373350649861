<template>
  <div class="HomeProduct">
    <HomePanel title="查询退款">
      <div class="search">
        <p><label for="ht">合同编号：</label><input type="text" id="ht" placeholder="合同编号" v-model="search.contractNo"/></p>
        <p><label for="mobile">手机号：</label><input type="text" id="mobile" placeholder="付款人手机号" v-model="search.mobile"/></p>
        <button @click="btnsearch">查询</button>
        <!--<Toast v-if="toastData.showToast" :message="toastData.toastMessage" />-->
      </div>
      <!-- 面板内容 -->
      <Transition name='fade' ref="target">
        <div class="goods-detail" v-if="order">
          <p class="title">
            <span>时间：</span> {{ order.name }}</p>
          <p class="title">
            <span>订单号：</span> {{ order.name }}</p>
          <p class="title"><span>金额：</span>  &yen;{{ order.price/100 }}</p>
          <p class="title"><span>购买型号：</span>{{ order.username }}</p>
          <p class="title"><span>状态：</span> {{order.status}}</p>
          <button @click="btnrefund" v-if="order.status===1">退款</button>
        </div>
        <div class="blank" v-else>空空如也</div>
      </Transition>
    </HomePanel>
  </div>
</template>

<script>
  import { ref, reactive } from "vue";
  import HomePanel from "./../home/components/home-panel.vue";
  // import HomeSkeleton from "./../home/components/home-skeleton.vue";
  import {uselazyData} from '@/hooks/index'
  import { findNew } from "@/api/home";
  import { ElMessage } from 'element-plus'
  // import Toast, { useToastEffect } from './components/Toast'
  export default {
    name: "refund",
    components: { HomePanel },
    setup() {
      const search = reactive({contractNo: '', mobile: ''})
      // const { toastData, showToast } = useToastEffect()
      const order = ref(null)
      const btnsearch = () =>{
        console.log('search ', search)
        // 仅作非空和长度校验
        if (!search.contractNo || search.contractNo.length > 12) {
          ElMessage({
            message: '请输入正确的合同编号.',
            type: 'warning',
          })
          return
        }
        let reg= /^\d{11}$/;
        if (!reg.test(search.mobile)) {
          ElMessage({
            message: '请输入正确的手机号.',
            type: 'warning',
          })
          return
        }
        // findNew().then(data => {
        //   // console.log(data.result)
        //   order.value = data.result[0]
        // });
        console.log('builder data success')
        let result = {name: 'ses', price:'10', username:'yy', phone:'13121', status: 1}
        order.value = result
      }
      const btnrefund = () => {
        console.log('refund order ', order.value)
      }
      const target = ref(null)

      return { search, target, btnsearch, btnrefund, order };
    }
  }
</script>

<style lang="less" scoped>
  .search {
    margin: 0 auto;
    border: 1px solid rgba(200, 200, 200, 0.8);
    border-radius: 5px;
    padding-left: 50px;
    p {
      margin-top: 20px;
      font-size: 18px;
      color: #27BA9B;
      display: flex;
      flex-direction: row;
      /*justify-content: flex-start;*/
      label {
        display: block;
        width: 100px;
      }
      input {
        font-size: 16px;
      }
    }
    button {
      margin-top: 10px;
      margin-bottom: 20px;
      padding: 2px 8px;
      font-size: 18px;
      background-color: blueviolet;
      border: 0;
      color: white;
      border-radius: 5px;
    }
  }
  .goods-detail {
    margin: 20px auto;
    width: 100%;
    border: 1px solid rgba(200, 200, 200, 0.8);
    border-radius: 5px;
    padding-bottom: 20px;
    .title {
      width: 80%;
      font-size: 18px;
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      span {
        margin-left: 200px;
        width: 180px;
        display: block;
        text-align: left;
        color: #27BA9B;
      }
    }

    .price {
      color: @priceColor;
    }
    button {
      margin: 50px 0 20px 200px;
      padding: 5px 0;
      font-size: 20px;
      width: 200px;
      text-align: center;
      background-color: blueviolet;
      border: 0;
      color: white;
      border-radius: 5px;
    }
  }
  .blank {
    margin: 30px auto;
    padding-bottom: 20px;
    width: 80%;
    text-align: center;
    color: #ccc;
    font-size: 16px;
  }
</style>
